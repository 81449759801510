import React, { Component } from "react";
import PropTypes from "prop-types";
import Link from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { FaRoute } from "react-icons/fa";

const Diensten = () => {
  return (
    <Layout bodyClass=" " headerClass="fixed top-0 z-50 bg-white mb-20">
      <SEO title="Over ons" />
      <div className="mt-20 container mx-auto">
        <h2 class="w-full my-2 text-5xl font-bold leading-tight text-center text-blue-500 mt-24 pt-5">
          Over ons
        </h2>
        <div class="w-full mb-4">
          <div class="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
        </div>
      </div>

      <div class="h-64 mt-20 bg-para" style={{backgroundImage: "url('/Over ons.jpg')"}}>
            </div>

      <div className="container min-h-screen mx-auto">
        <div className=" mb-6 flex flex-col items-start">
          <section className="w-4/5 md:w-3/5 mx-auto mt-10 mb-20">
            <h2 className="w-full my-2 text-2xl font-bold leading-tight uppercase text-blue-500 mt-8 mb-1 text-center">
              Smart Fleet biedt
            </h2>
            <div class="w-full mb-4">
            </div>
            <p>
              Optimalisatie van uw nieuw of bestaand wagenpark rekening houdend
              met nieuwe wetgevingen hieromtrent.
              <br />
              Het uit handen nemen van zowel het operationele als
              administratieve aspect van uw wagenpark. Dit met een persoonlijke
              aanpak.
            </p>
            <p className="mt-4 mb-24 text-lg text-blue-500 font-bold">
              Zo kan u zich volledig richten op uw corebusiness.
            </p>
            <h2 className="w-full my-2 text-2xl font-bold leading-tight uppercase text-blue-500 mt-8 mb-1 text-center">
              Wat doen we?
            </h2>
            <div class="w-full mb-4">
            </div>

            <p>
              We beheren uw vloot als goede huisvader en geven raad. Samen
              zoeken we naar de meest rendabele oplossing voor u.
            </p>
            <p className="mt-4 mb-24 text-lg text-blue-500 font-bold">
              “We beheren de wagens voor onze klanten alsof ze de onze zijn” is
              dus niet misplaatst.
            </p>

            <h2 className="w-full my-2 text-2xl font-bold leading-tight uppercase text-blue-500 mt-8 mb-1 text-center">
              Wat is ons doel?
            </h2>
            <div class="w-full mb-4">
            </div>

            <p>
              Bij Smart Fleet is ons doel u een zorgeloos wagenpark te bezorgen
              terwijl we dit financieel en fiscaal optimaliseren. Zo creëren we
              als het ware een win-win situatie.
            </p>
            <p className="mt-4 mb-24 text-lg text-blue-500 font-bold">
              Samen groeien staat bij ons centraal.
            </p>
          </section>
        </div>
      </div>
      <div class="relative footer-wave w-full -mb-20 bg-blue-100">
        <svg
          viewBox="0 0 1428 174"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xlink="http://www.w3.org/1999/xlink"
        >
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g
              transform="translate(-2.000000, 44.000000)"
              fill="#FFFFFF"
              fill-rule="nonzero"
            >
              <path
                d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496"
                opacity="0.100000001"
              ></path>
              <path
                d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
                opacity="0.100000001"
              ></path>
              <path
                d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z"
                id="Path-4"
                opacity="0.200000003"
              ></path>
            </g>
            <g
              transform="translate(-4.000000, 76.000000)"
              fill="#FFFFFF"
              fill-rule="nonzero"
            >
              <path d="M0.457,34.035 C57.086,53.198 98.208,65.809 123.822,71.865 C181.454,85.495 234.295,90.29 272.033,93.459 C311.355,96.759 396.635,95.801 461.025,91.663 C486.76,90.01 518.727,86.372 556.926,80.752 C595.747,74.596 622.372,70.008 636.799,66.991 C663.913,61.324 712.501,49.503 727.605,46.128 C780.47,34.317 818.839,22.532 856.324,15.904 C922.689,4.169 955.676,2.522 1011.185,0.432 C1060.705,1.477 1097.39,3.129 1121.236,5.387 C1161.703,9.219 1208.621,17.821 1235.4,22.304 C1285.855,30.748 1354.351,47.432 1440.886,72.354 L1441.191,104.352 L1.121,104.031 L0.457,34.035 Z"></path>
            </g>
          </g>
        </svg>
      </div>
    </Layout>
  );
};

export default Diensten;
